import React from 'react';
import { ComputerGraphic } from '../Graphics';
import { Link } from 'react-router-dom';


export default class ResumeProjects extends React.Component {
  render() {
    const images = ['/images/friendbook7.jpg', '/images/superbolt_1.jpg', '/images/trello10.jpg', '/images/yelp3.jpg', '/images/superbolt_4.jpg', '/images/shape_generator_2.jpg'];

    return (
      <div className='pg-resume--section'>
        <div className='pg-resume--projects--inner'>
          <div className='pg-resume--projects--graphic-wrapper pg-resume--section--col'>
            <div className='pg-resume--projects--graphic'>
              <Link to='/portfolio'>
                <ComputerGraphic images={ images } />
              </Link>
            </div>
          </div>

          <div className='pg-resume--projects--links pg-resume--section--col'>
            <div className='pg-resume--projects--text'>
              <h2 className='b-h2 b-h2-glow-blue'>
                <Link className='b-link-arrow' to='/portfolio'>
                  <span>Portfolio</span>
                  <span className='arrow-right'>→</span>
                </Link>
              </h2>

              <div className='pg-resume--projects--description'>
                <p>Check out some of my personal projects! Many of my projects are full-stack clones and re-imaginings of existing popular websites, such as Trello, Facebook, Twitter, Yelp, Reddit, 4chan, Bandcamp, Medium, and Politico. I used a different set of frameworks and tools for each project, and they were all valuable learning experiences for me.</p>
              </div>
            </div>

            <div className='pg-resume--projects--text'>
              <h2 className='b-h2 b-h2-glow-blue'>
                <Link className='b-link-arrow' to='/professional-work'>
                  <span>Professional Work</span>
                  <span className='arrow-right'>→</span>
                </Link>
              </h2>

              <div className='pg-resume--projects--description'>
                <p>I work professionally as the primary Web Developer for <a href='https://www.superbolt.agency' target='_blank' rel='noopener noreferrer'>Superbolt</a>, a full-service marketing agency. I mostly focus on ecommerce, but I have also done some work on back-end Ruby APIs, data visualization charts, and other types of projects.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
