export default (target, n) => {
  const timing = Number(n);
  let el = target;
  let parent;
  let dropdown;
  let content;

  if (isNaN(timing)) { return; }

  while (el) {
    parent = el.parentElement;

    if (parent && parent.classList.contains('js-dropdown-wrapper')) {
      break;
    }

    el = parent;
  }

  if (parent && parent.classList.contains('js-dropdown-wrapper')) {
    dropdown = parent.querySelector('.js-dropdown');

    if (dropdown) {
      content = dropdown.querySelector('.js-dropdown-content');
    }
  }

  if (dropdown && content && parent && !dropdown.classList.contains('animating')) {
    if (parent.classList.contains('open')) {
      parent.classList.remove('open');
      dropdown.classList.add('animating');
      dropdown.style.height = content.offsetHeight + 'px';

      setTimeout(() => {
        dropdown.style.overflow = 'hidden';
        dropdown.style.transition = `height ${ timing / 1000 }s ease`;
        dropdown.style.height = 0;
      }, 50);

      setTimeout(() => {
        dropdown.style.display = 'none';
        dropdown.style.height = 'auto';
        dropdown.style.overflow = 'auto';
        dropdown.classList.remove('animating');
      }, timing + 100);
    } else {
      parent.classList.add('open');
      dropdown.classList.add('animating');
      dropdown.style.height = 0;
      dropdown.style.overflow = 'hidden';
      dropdown.style.display = 'block';
      dropdown.style.transition = `height ${ timing / 1000 }s ease`;
      dropdown.style.height = content.offsetHeight + 'px';

      setTimeout(() => {
        dropdown.style.overflow = 'auto';
        dropdown.style.height = 'auto';
        dropdown.classList.remove('animating');
      }, timing + 100);
    }
  }
}
