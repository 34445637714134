import React from 'react';
import { PageFadeTransition } from '../Misc';
import { ResumeProjects,
         ResumeSummary,
         ResumeSkills,
         ResumeEducation,
         ResumeExperience } from '.';


export default class Resume extends React.Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <PageFadeTransition sectionName='Resume'>
        <section className='pg-resume b-section--padding'>
          <div className='pg-resume--inner b-page-width'>
            <div>
              <ResumeSummary />
              <ResumeProjects />
              <ResumeExperience />
              <ResumeEducation />
              <ResumeSkills />
            </div>
          </div>
        </section>
      </PageFadeTransition>
    );
  }
}
