import React from 'react';
import { ComputerGraphic } from '../Graphics';


export default class ProfessionalHero extends React.Component {
  render() {
    const images = ['/images/superbolt_1.jpg', '/images/superbolt_2.jpg', '/images/superbolt_4.jpg', '/images/superbolt_3.jpg'];

    return (
      <div className='pg-professional--section top'>
        <div className='pg-professional--section--inner'>
          <div className='pg-professional--section--header pg-professional--section--col'>
            <div className='pg-professional--section--title'>
              <h1 className='b-h1 b-h1-glow-blue'>
                <span>Professional Work</span>
              </h1>
            </div>

            <div className='pg-professional--section--text-content'>
              <p>I am the primary Web Developer at <a href='https://www.superbolt.agency' target='_blank' rel='noopener noreferrer'>Superbolt</a>, a full-service marketing agency. Since starting in 2018, I have worked on dozens of Shopify stores for our diverse range of clients. Even though our web development services are mostly ecommerce based, I have also done some work building back-end APIs, data visualization charts, and other types of projects.</p>
              <p>I have also spent a considerable amount of time developing internal tools for the company. The <a href='https://www.superbolt.agency' target='_blank' rel='noopener noreferrer'>Superbolt website</a>, which I built with Craft CMS, includes an interactive design gallery, an employee blog, and a personalized dashboard that clients can log in to and view their weekly performance stats. Check it out!</p>
            </div>
          </div>

          <div className='pg-professional--section--content pg-professional--section--col'>
            <div className='pg-professional--section--graphic'>
              <ComputerGraphic images={ images } />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
