import React from 'react';


export default class EmailIcon extends React.Component {
  render() {
    return (
      <svg className='b-icon--email' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.558 41">
        <g id="Group_706" data-name="Group 706" transform="translate(-839.085 -241.485)">
          <path id="Path_559" data-name="Path 559" d="M868.367,268.414a5.835,5.835,0,0,1-3.82-1.5l-24.488-21.1,1-1.065,24.489,21.1a4.1,4.1,0,0,0,5.64,0l24.489-21.1,1,1.065-24.489,21.1A5.835,5.835,0,0,1,868.367,268.414Z" transform="translate(-0.008 -0.045)" strokeMiterlimit="10" strokeWidth="0.5"></path>
          <rect id="Rectangle_1182" data-name="Rectangle 1182" width="1.432" height="24.904" transform="matrix(0.669, -0.744, 0.744, 0.669, 877.188, 263.676)" strokeMiterlimit="10" strokeWidth="0.5"></rect>
          <rect id="Rectangle_1183" data-name="Rectangle 1183" width="24.647" height="1.432" transform="translate(840.051 279.259) rotate(-41.953)"  strokeMiterlimit="10" strokeWidth="0.5"></rect>
          <path id="Path_560" data-name="Path 560" d="M892.013,281.985h-47.3a5.136,5.136,0,0,1-5.13-5.13V247.115a5.136,5.136,0,0,1,5.13-5.13h47.3a5.136,5.136,0,0,1,5.13,5.13v29.739A5.136,5.136,0,0,1,892.013,281.985Zm-47.3-38.507a3.642,3.642,0,0,0-3.638,3.638v29.739a3.642,3.642,0,0,0,3.638,3.638h47.3a3.642,3.642,0,0,0,3.638-3.638V247.115a3.642,3.642,0,0,0-3.638-3.638Z" transform="translate(0)" strokeMiterlimit="10" strokeWidth="0.5"></path>
        </g>
      </svg>
    );
  }
}
