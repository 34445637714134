import React from 'react';
import { CraftIcon, ShopifyIcon } from '../Graphics';


export default class ProfessionalPlatforms extends React.Component {
  render() {
    return (
      <div className='pg-professional--section'>
        <div className='pg-professional--section--inner mobile-reverse'>
          <div className='pg-professional--section--col'>
            <ul className='pg-professional--graphics-list'>
              <li className='pg-professional--graphics-list-item'>
                <ShopifyIcon />
              </li>

              <li className='pg-professional--graphics-list-item'>
                <CraftIcon />
              </li>
            </ul>
          </div>

          <div className='pg-professional--section--col'>
            <div className='pg-professional--section--title'>
              <h2 className='b-h2 b-h2-glow-blue'>Platforms</h2>
            </div>

            <div className='pg-professional--section--text-content'>
              <p>My professional work has mostly been focused on the <a href='https://www.shopify.com/' target='_blank' rel='noopener noreferrer'>Shopify</a> and <a href='https://craftcms.com/' target='_blank' rel='noopener noreferrer'>Craft CMS</a> platforms.</p>
              <p>I have worked on many different ecommerce stores, some of which were built from the ground up, and others which were inherited from other development agencies.</p>
              <p>Each ecommerce store has a unique set of features and requirements, so I am always kept on my toes!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
