import React from 'react';


export default class NothingHere extends React.Component {
  render() {
    return (
      <div className='b-nothing-here'>
        <p>Oops, nothing here!</p>
        <p>404</p>
      </div>
    );
  }
}
