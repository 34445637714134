import React from 'react';


export default class ShopifyIcon extends React.Component {
  render() {
    return (
      <a className='s-shopify-icon s-graphic-icon-link' href='https://www.shopify.com/' target='_blank' rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px"
        	 viewBox="0 0 200.4 66.5" xmlSpace="preserve">
          <g>
          	<path className="graphic-fill fill-primary" d="M43.6,15.3c0-0.3-0.3-0.4-0.5-0.5c-0.2,0-4.5-0.3-4.5-0.3s-3-3-3.3-3.3s-1-0.2-1.2-0.2c0,0-0.7,0.2-1.7,0.5
          		c-1-2.9-2.7-5.5-5.8-5.5h-0.2c-0.9-1.1-2-1.7-2.9-1.7c-7.2,0-10.6,9-11.7,13.6c-2.8,0.9-4.8,1.5-5,1.5c-1.5,0.5-1.6,0.5-1.8,2
          		C4.8,22.4,0.8,54,0.8,54l31.7,5.9l17.2-3.6C49.7,56.3,43.7,15.6,43.6,15.3z M30.7,12.1c-0.8,0.3-1.7,0.5-2.7,0.8v-0.6
          		c0-1.8-0.3-3.2-0.7-4.3C29,8.1,30.1,10,30.7,12.1z M25.5,8.4c0.4,1.1,0.7,2.7,0.7,4.8v0.3c-1.7,0.5-3.6,1.1-5.5,1.7
          		C21.7,11.1,23.7,9.1,25.5,8.4z M23.3,6.4c0.3,0,0.6,0.1,0.9,0.3c-2.3,1.1-4.8,3.8-5.8,9.3c-1.5,0.5-3,0.9-4.4,1.3
          		C15.3,13.1,18.2,6.4,23.3,6.4z"/>
          	<path className="graphic-fill fill-primary" d="M43.1,14.8c-0.2,0-4.5-0.3-4.5-0.3s-3-3-3.3-3.3C35.2,11.1,35,11,34.8,11l-2.4,49l17.3-3.7
          		c0,0-6-40.8-6.1-41.1C43.6,14.9,43.3,14.8,43.1,14.8z"/>
          	<path className="graphic-fill-light fill-white" d="M26.7,24.3l-2.1,6.3c0,0-1.9-1-4.1-1c-3.3,0-3.5,2.1-3.5,2.6c0,2.9,7.5,4,7.5,10.7c0,5.3-3.4,8.7-7.9,8.7
          		c-5.4,0-8.2-3.4-8.2-3.4l1.4-4.8c0,0,2.9,2.5,5.3,2.5c1.6,0,2.2-1.2,2.2-2.2c0-3.8-6.2-3.9-6.2-10.1c0-5.2,3.7-10.2,11.3-10.2
          		C25.3,23.4,26.7,24.3,26.7,24.3z"/>
          	<path className="graphic-fill fill-primary" d="M69.8,36.1c-1.7-0.9-2.6-1.7-2.6-2.8c0-1.4,1.2-2.3,3.2-2.3c2.2,0,4.2,0.9,4.2,0.9l1.6-4.8
          		c0,0-1.4-1.1-5.7-1.1c-5.9,0-10,3.4-10,8.1c0,2.7,1.9,4.8,4.5,6.2c2.1,1.2,2.8,2,2.8,3.2c0,1.3-1,2.3-3,2.3c-2.9,0-5.5-1.5-5.5-1.5
          		l-1.7,4.8c0,0,2.5,1.7,6.7,1.7c6.1,0,10.4-3,10.4-8.4C74.6,39.7,72.4,37.7,69.8,36.1L69.8,36.1z M94,26.1c-3,0-5.3,1.4-7.2,3.6
          		h-0.1L89.3,16h-6.8l-6.6,34.6h6.8L85,38.8c0.9-4.5,3.2-7.2,5.3-7.2c1.5,0,2.1,1,2.1,2.5c0,0.9-0.1,2.1-0.3,3l-2.6,13.5h6.9l2.7-14
          		c0.3-1.5,0.5-3.2,0.5-4.4C99.5,28.4,97.5,26.1,94,26.1L94,26.1z M114.8,26.1c-8.1,0-13.5,7.4-13.5,15.5c0,5.3,3.2,9.5,9.3,9.5
          		c8,0,13.4-7.2,13.4-15.5C124,30.6,121.1,26.1,114.8,26.1L114.8,26.1z M111.5,45.8c-2.3,0-3.3-2-3.3-4.4c0-3.9,2-10.2,5.7-10.2
          		c2.4,0,3.2,2.1,3.2,4.1C117,39.4,115,45.8,111.5,45.8z M141.3,26.1c-4.6,0-7.2,4-7.2,4H134l0.4-3.6h-6c-0.3,2.5-0.8,6.2-1.4,9
          		l-4.6,24.7h6.7l1.9-10h0.1c0,0,1.4,0.9,4,0.9c7.9,0,13.1-8.1,13.1-16.4C148.2,30.1,146.2,26.1,141.3,26.1L141.3,26.1z M134.8,45.8
          		c-1.8,0-2.8-1-2.8-1l1.1-6.3c0.8-4.2,3-7,5.3-7c2.1,0,2.7,1.9,2.7,3.7C141.2,39.7,138.6,45.8,134.8,45.8z M157.9,16.3
          		c-2.2,0-3.9,1.7-3.9,3.9c0,2,1.3,3.4,3.2,3.4h0.1c2.1,0,3.9-1.4,4-3.9C161.3,17.8,159.9,16.3,157.9,16.3L157.9,16.3z M148.4,50.4
          		h6.8l4.6-24H153 M177,26.6h-4.7l0.2-1.1c0.4-2.3,1.8-4.4,4-4.4c1.2,0,2.2,0.3,2.2,0.3l1.3-5.3c0,0-1.2-0.6-3.7-0.6
          		c-2.4,0-4.8,0.7-6.6,2.3c-2.3,2-3.4,4.8-3.9,7.6l-0.2,1.1h-3.2l-1,5.1h3.2L161,50.4h6.8l3.6-18.9h4.7L177,26.6L177,26.6z
          		 M193.2,26.6c0,0-4.2,10.7-6.1,16.5H187c-0.1-1.9-1.7-16.5-1.7-16.5h-7.1l4.1,22c0.1,0.5,0,0.8-0.1,1.1c-0.8,1.5-2.1,3-3.7,4.1
          		c-1.3,0.9-2.7,1.5-3.8,1.9l1.9,5.7c1.4-0.3,4.2-1.4,6.6-3.7c3.1-2.9,5.9-7.4,8.9-13.4l8.3-17.7L193.2,26.6L193.2,26.6z"/>
          </g>
        </svg>
      </a>
    );
  }
}
