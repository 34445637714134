import React from 'react';
import { ResumeSection } from '.';


export default class ResumeSkills extends React.Component {
  render() {
    return (
      <ResumeSection sectionName='Skills'>
        <div className='pg-resume--skills'>
          <ul className='pg-resume--skills-list'>
            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Languages</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>JavaScript (ES5, ES6+)</li>
                  <li>Ruby</li>
                  <li>SQL</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Frameworks / Libraries</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>Express</li>
                  <li>Ruby on Rails</li>
                  <li>jQuery</li>
                  <li>React/Redux</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>CMS</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>Craft CMS</li>
                  <li>Shopify</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Markup / Styling / Templating</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>HTML</li>
                  <li>CSS / SCSS</li>
                  <li>ERB</li>
                  <li>Handlebars</li>
                  <li>Liquid</li>
                  <li>Twig</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Testing</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>Jest</li>
                  <li>RSpec</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Tracking</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>Facebook Pixels</li>
                  <li>Google Ads</li>
                  <li>Google Analytics</li>
                  <li>Google Tag Manager</li>
                  <li>Various other social media platforms</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Version Control</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>Git</li>
                  <li>GitHub</li>
                  <li>Bitbucket</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Email Platforms</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>Hubspot</li>
                  <li>Klaviyo</li>
                  <li>Listrak</li>
                  <li>Mailchimp</li>
                </ul>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--content-small-title'>
                <span>Other Relevant Skills</span>
              </div>

              <div className='pg-resume--content-details'>
                <ul>
                  <li>Adobe Photoshop</li>
                  <li>Csound</li>
                  <li>D3.js</li>
                  <li>Karaoke</li>
                  <li>Mario Kart</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </ResumeSection>
    );
  }
}
