const initialState = {
  title: 'rgilbert_DEV',
  message: { content: false, type: 'error' },
  projects: [],
  currentProject: undefined,
  projectModalOpen: false
};


const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MESSAGE':
      return { ...state,
        message: {
          content: action.payload.content,
          type: action.payload.type
        },
      };
    case 'SET_PROJECTS':
      return { ...state,
        projects: action.payload,
      };
    case 'SET_CURRENT_PROJECT':
      return { ...state,
        currentProject: action.payload,
      };
    case 'SET_PROJECT_MODAL_STATUS':
      return { ...state,
        projectModalOpen: action.payload,
      };
    default:
      return state;
  }
}

export default rootReducer;
