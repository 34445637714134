import React from 'react';
import { ResumeSection } from '.';


export default class ResumeEducation extends React.Component {
  render() {
    return (
      <ResumeSection sectionName='Education'>
        <div className='pg-resume--education'>
          <div className='pg-resume--content-list-item'>
            <h3 className='b-h4'>Berklee College of Music</h3>

            <div className='pg-resume--content-details'>
              <div className='pg-resume--experience-subtitle'>
                <div>B.A. in Audio Engineering / Sound Design</div>
                <div>Boston, MA</div>
              </div>
            </div>
          </div>
        </div>
      </ResumeSection>
    );
  }
}
