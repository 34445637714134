import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageFadeTransition } from '../Misc';


export default class Homepage extends React.Component {
  render() {
    return (
      <PageFadeTransition sectionName='Homepage'>
        <section className='pg-homepage b-section--padding'>
          <div className='b-page-width'>
            <div className='b-section--header'>
              <Redirect to='/resume' />
            </div>
          </div>
        </section>
      </PageFadeTransition>
    );
  }
}
