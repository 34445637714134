import React from 'react';
import { motion } from 'framer-motion';


export const Variants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 }
};


export const Transition = {
  duration: 0.4
};


export default class PageFadeTransition extends React.Component {
  render() {
    return (
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={ Variants }
        transition={ Transition }
      >
        { this.props.children }
      </motion.div>
    );
  }
}
