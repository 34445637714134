import React from 'react';


export default class GoogleIcon extends React.Component {
  render() {
    return (
      <a className='s-google-icon s-graphic-icon-link' href='https://marketingplatform.google.com/about/analytics/' target='_blank' rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px"
        	 viewBox="0 0 200.4 66.5" xmlSpace="preserve">
          <g>
          	<path id="g_1_" className="graphic-fill fill-primary" d="M51.5,24.4H29V31h16c-0.8,9.3-8.6,13.4-15.9,13.4c-9.4,0-17.6-7.4-17.6-17.7
          		c0-10.1,7.8-17.9,17.6-17.9c7.6,0,12,4.8,12,4.8l4.6-4.8c0,0-6-6.6-16.9-6.6C14.9,2.1,4.2,13.8,4.2,26.5c0,12.4,10.1,24.5,25,24.5
          		c13.1,0,22.7-8.9,22.7-22.2C51.9,26,51.5,24.4,51.5,24.4L51.5,24.4z"/>
          	<path id="o_2_" className="graphic-fill fill-primary" d="M69.8,19.5c-9.2,0-15.8,7.2-15.8,15.6C54,43.7,60.4,51,70,51c8.7,0,15.7-6.5,15.7-15.7
          		C85.6,24.9,77.4,19.5,69.8,19.5L69.8,19.5z M69.9,25.8c4.5,0,8.8,3.7,8.8,9.5s-4.2,9.5-8.8,9.5c-5,0-8.9-4-8.9-9.6
          		C60.9,29.8,64.8,25.8,69.9,25.8L69.9,25.8z"/>
          	<path id="o_1_" className="graphic-fill fill-primary" d="M104.2,19.5c-9.2,0-15.8,7.2-15.8,15.6c0,8.6,6.4,15.9,16,15.9c8.7,0,15.7-6.5,15.7-15.7
          		C120,24.9,111.8,19.5,104.2,19.5L104.2,19.5z M104.3,25.8c4.5,0,8.8,3.7,8.8,9.5s-4.2,9.5-8.8,9.5c-5,0-8.9-4-8.9-9.6
          		C95.3,29.8,99.2,25.8,104.3,25.8L104.3,25.8z"/>
          	<path id="g" className="graphic-fill fill-primary" d="M137.9,19.6c-8.5,0-15.1,7.4-15.1,15.7c0,9.4,7.7,15.8,14.9,15.8c4.5,0,6.8-1.7,8.7-3.8v3.1
          		c0,5.4-3.3,8.7-8.3,8.7c-4.8,0-7.2-3.6-8.1-5.6l-6.1,2.5c2.1,4.5,6.4,9.2,14.1,9.2c8.4,0,14.8-5.3,14.8-16.4V20.5h-6.6v2.7
          		C144.4,21,141.6,19.6,137.9,19.6L137.9,19.6z M138.5,25.8c4.1,0,8.4,3.6,8.4,9.6c0,6.2-4.2,9.5-8.5,9.5c-4.5,0-8.7-3.7-8.7-9.4
          		C129.8,29.3,134.1,25.8,138.5,25.8L138.5,25.8z"/>
          	<path id="e" className="graphic-fill fill-primary" d="M182.4,19.5c-8,0-14.7,6.3-14.7,15.7c0,9.9,7.5,15.8,15.4,15.8c6.6,0,10.8-3.7,13.2-6.9l-5.5-3.7
          		c-1.4,2.2-3.8,4.3-7.7,4.3c-4.4,0-6.4-2.4-7.7-4.8l21.1-8.8l-1.1-2.6C193.3,23.7,188.6,19.5,182.4,19.5L182.4,19.5z M182.6,25.6
          		c2.9,0,4.9,1.5,5.9,3.4l-14,5.9C173.7,30.3,178.1,25.6,182.6,25.6L182.6,25.6z"/>
          	<path id="l" className="graphic-fill fill-primary" d="M157.7,50.1h6.9V3.7h-6.9V50.1z"/>
          </g>
        </svg>
      </a>
    );
  }
}
