import React from 'react';
import { Header, Main, Footer } from '.';
import { adjustSidebarOnScroll } from '../../services/misc';


export default class App extends React.Component {

  // ==========================================================================

  componentDidMount() {
    const body = document.querySelector('body');

    window.addEventListener('scroll', adjustSidebarOnScroll);
    window.addEventListener('resize', adjustSidebarOnScroll);
    adjustSidebarOnScroll();

    setTimeout(() => {
      body.classList.add('fade-in');
    }, 500);
  }

  // ==========================================================================

  componentWillUnmount() {
    window.removeEventListener('scroll', adjustSidebarOnScroll);
    window.removeEventListener('resize', adjustSidebarOnScroll);
  }

  // ==========================================================================

  render() {
    return (
      <div className='s-app'>
        <Header />
        <Main { ...this.props } />
        <Footer />
      </div>
    );
  }
}
