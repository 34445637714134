import React from 'react';
import { FacebookIcon, GoogleIcon } from '../Graphics';


export default class ProfessionalTracking extends React.Component {
  render() {
    return (
      <div className='pg-professional--section'>
        <div className='pg-professional--section--inner'>
          <div className='pg-professional--section--col'>
            <div className='pg-professional--section--title'>
              <h2 className='b-h2 b-h2-glow-blue'>Tracking</h2>
            </div>

            <div className='pg-professional--section--text-content'>
              <p>Conversion tracking is a big part of my day-to-day work. Ecommerce store owners need to measure the shopping behavior of their customers so that they can better optimize their websites and plan their ad campaign strategy.</p>
              <p>The primary tools that I use for tracking are <a href='https://www.facebook.com/business/learn/facebook-ads-pixel' target='_blank' rel='noopener noreferrer'>Facebook Pixels</a>, <a href='https://ads.google.com/home/' target='_blank' rel='noopener noreferrer'>Google Ads</a>, <a href='https://marketingplatform.google.com/about/analytics/' target='_blank' rel='noopener noreferrer'>Google Analytics</a>, and <a href='https://tagmanager.google.com/' target='_blank' rel='noopener noreferrer'>Google Tag Manager</a>, but I have also set up tracking networks for many other ad platforms.</p>
            </div>
          </div>

          <div className='pg-professional--section--col'>
            <ul className='pg-professional--graphics-list'>
              <li className='pg-professional--graphics-list-item'>
                <FacebookIcon />
              </li>

              <li className='pg-professional--graphics-list-item'>
                <GoogleIcon />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
