import React              from 'react';
import { ProjectPreview } from '.';
import { connect }        from 'react-redux';


class ProjectsList extends React.Component {

  constructor(props) {
    super(props);
    this.makeList = this.makeList.bind(this);
  }

  // ==========================================================================

  makeList() {
    const list = this.props.projects.map((project) => {
      let showProject = true;

      this.props.filterTags.forEach((tag) => {
        if (project.tags.indexOf(tag) === -1) {
          showProject = false;
        }
      });

      if (showProject) {
        return (
          <li className='pg-portfolio--list-item'
              data-tags={ project.tags }
              key={ project.handle }>
            <ProjectPreview project={ project } />
          </li>
        );
      } else {
        return false;
      }
    });

    return list.filter((item) => {
      return !!item;
    });
  }

  // ==========================================================================

  render() {
    let content = this.makeList();

    if (!content.length) {
      content = (
        <li className='pg-portfolio--list-item-empty'>
          <p>No results</p>
        </li>
      );
    }

    return (
      <div className='pg-portfolio--list-wrapper'>
        <ul className='pg-portfolio--list'>
          { content }
        </ul>
      </div>
    );
  }
}


// REDUX ======================================================================

const mapStateToProps = (state) => {
  return {
    projects: state.projects
  };
};

const component = connect(mapStateToProps)(ProjectsList);

export default component;
