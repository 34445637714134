export default (path) => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status === 200) {
          let data;

          try {
            data = JSON.parse(request.responseText);
          } catch (e) {
            reject();
          }

          resolve(data);
        } else {
          reject();
        }
      }
    }

    request.open('GET', path);
    request.setRequestHeader('Content-Type', 'application/json');
    request.send();
  });
}
