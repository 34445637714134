import React from 'react';


export default class ComputerGraphic extends React.Component {
  render() {
    const imageElements = this.props.images.map((image, i) => {
      let idx = i + 1;

      if (idx === 1 && this.props.images.length === 1) {
        return (
          <g opacity="0" fill='freeze' key={ `Graphic Image - ${ i }`}>
            <image id="computer_graphic_screen_1" width="497" height="249" transform="translate(17547 -20705)" xlinkHref={ image } />
            <animate attributeName='opacity' from='0.5s' to='1' dur='1s' begin='computer_graphic_loading_dots_anim_2.end' fill='freeze' id='computer_graphic_screen_anim_in_1' />
          </g>
        );
      } else if (idx === 1) {
        return (
          <g opacity="0" fill='freeze' key={ `Graphic Image - ${ i }`}>
            <image id="computer_graphic_screen_1" width="497" height="249" transform="translate(17547 -20705)" xlinkHref={ image } />
            <animate attributeName='opacity' from='0' to='1' dur='1s' begin='computer_graphic_loading_dots_anim_2.end; computer_graphic_screen_anim_out_final.begin+0.5s' fill='freeze' id='computer_graphic_screen_anim_in_1' />
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='computer_graphic_screen_anim_in_1.end+4.5s' fill='freeze' id='computer_graphic_screen_anim_out_1' />
          </g>
        );
      } else if (idx === this.props.images.length) {
        return (
          <g opacity="0" fill='freeze' key={ `Graphic Image - ${ i }`}>
            <image id={ `computer_graphic_screen_${ idx }` } width="497" height="249" transform="translate(17547 -20705)" xlinkHref={ image } />
            <animate attributeName='opacity' from='0' to='1' dur='1s' begin={ `computer_graphic_screen_anim_out_${ idx - 1 }.begin+0.5s` } fill='freeze' id='computer_graphic_screen_anim_in_final' />
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='computer_graphic_screen_anim_in_final.end+4.5s' fill='freeze' id='computer_graphic_screen_anim_out_final' />
          </g>
        );
      } else {
        return (
          <g opacity="0" fill='freeze' key={ `Graphic Image - ${ i }`}>
            <image id={ `computer_graphic_screen_${ idx }` } width="497" height="249" transform="translate(17547 -20705)" xlinkHref={ image } />
            <animate attributeName='opacity' from='0' to='1' dur='1s' begin={ `computer_graphic_screen_anim_out_${ idx - 1 }.begin+0.5s` } fill='freeze' id={ `computer_graphic_screen_anim_in_${ idx }` } />
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin={ `computer_graphic_screen_anim_in_${ idx }.end+4.5s` } fill='freeze' id={ `computer_graphic_screen_anim_out_${ idx }` } />
          </g>
        );
      }
    });


    return (
      <div className='s-computer-graphic'>
        <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 0 498 274.4">
          <defs>
            <clipPath id="computer-graphic_clip-path">
              <path id="computer-graphic_Rectangle_1161" data-name="Rectangle 1161" d="M0,0H497a  0,0,0,0,1,0,0  V241  a8,8,0,0,1-8,8  H8  a8,8,0,0,1-8-8  V0  A0,0,0,0,1,0,0  Z" transform="translate(17547 -20705)" fill="none"/>
            </clipPath>
          </defs>

          <g id="computer-graphic_Group_684" data-name="Group 684" transform="translate(-734.176 -186.469)">

            {/* Images */}

            <g id="computer-graphic_Mask_Group_7" data-name="Mask Group 7" transform="translate(-16812 20916)" clipPath="url(#computer-graphic_clip-path)">
              { imageElements }
            </g>


            <g id="computer-graphic_Group_663" data-name="Group 663" transform="translate(734.676 186.969)">

              {/* Screen Top */}

              <path id="computer-graphic_Path_499" className='s-computer-graphic--stroke' data-name="Path 499" d="M983.218,353.819V336.954a7.533,7.533,0,0,0-7.533-7.533H493.849a7.533,7.533,0,0,0-7.533,7.533v16.865" transform="translate(-486.316 -329.421)" fill="none" stroke="none" strokeMiterlimit="10" strokeWidth="1"/>

              {/* Screen Bottom */}

              <g id="computer-graphic_Group_657" data-name="Group 657" transform="translate(0 24.398)" >
                <path id="computer-graphic_Path_500"
                  className='s-computer-graphic--stroke'
                  data-name="Path 500"
                  d="M983.218,428.967  h-496.9  V670  a7.533,7.533,0,0,0,7.533,7.533  H975.685  a7.533,7.533,0,0,0,7.533-7.533  Z"
                  transform="translate(-486.316 -428.967)" fill="rgba(51, 136, 239, 0.15)" stroke="none" strokeMiterlimit="10" strokeWidth="1">

                  <animate attributeName='fill' from='rgba(51, 136, 239, 0.15)' to='rgba(51, 136, 239, 0)' dur='1s' begin='2.5s' fill='freeze' />
                </path>
              </g>

              {/* Circle Buttons */}

              <g id="computer-graphic_Group_661" data-name="Group 661" transform="translate(11.329 7.822)">
                <g id="computer-graphic_Group_660" data-name="Group 660" transform="translate(0 0.657)">
                  <circle id="computer-graphic_Ellipse_15" className='s-computer-graphic--stroke' data-name="Ellipse 15" cx="3.095" cy="3.095" r="3.095"  transform="matrix(0.229, -0.974, 0.974, 0.229, 0, 6.027)" fill="#ea4335" stroke="none" strokeMiterlimit="10" strokeWidth="1"/>
                </g>

                <g id="computer-graphic_Group_659" data-name="Group 659" transform="translate(7.839 0)">
                  <circle id="computer-graphic_Ellipse_14" className='s-computer-graphic--stroke' data-name="Ellipse 14" cx="3.095" cy="3.095" r="3.095" transform="translate(0 4.378) rotate(-45)" fill="#fbbc04" stroke="none" strokeMiterlimit="10" strokeWidth="1"/>
                </g>

                <g id="computer-graphic_Group_658" data-name="Group 658" transform="translate(16.336 0)">
                  <circle id="computer-graphic_Ellipse_13" className='s-computer-graphic--stroke' data-name="Ellipse 13" cx="3.095" cy="3.095" r="3.095" transform="translate(0 4.378) rotate(-45)" fill="#34a853" stroke="none" strokeMiterlimit="10" strokeWidth="1"/>
                </g>
              </g>

              {/* URL Bar */}

              <g id="computer-graphic_Group_662" data-name="Group 662" transform="translate(123.401 7.535)">
                <rect id="computer-graphic_Rectangle_1160" className='s-computer-graphic--stroke' data-name="Rectangle 1160" width="283.956" height="10.33" rx="5.165" transform="translate(0)" fill="none" stroke="none" strokeMiterlimit="10" strokeWidth="1"/>
              </g>
            </g>
          </g>

          {/* Loading Dots */}

          <g opacity='0' id='computer_graphic_loading_dots'>
            <g>
              <circle id="computer_graphic_loading_dot_1" cx="215" cy="150" r="5" fill="#2848e6" stroke="none" strokeMiterlimit="10" strokeWidth="1">
                <animate attributeName='r' from='5' to='10' dur='0.25s' begin='computer_graphic_loading_dots_anim_1.end; computer_graphic_loading_dot_3_anim_1.end+1s' fill='freeze' id='computer_graphic_loading_dot_1_anim_1' />
                <animate attributeName='r' from='10' to='5' dur='0.25s' begin='computer_graphic_loading_dot_1_anim_1.end' fill='freeze' id='computer_graphic_loading_dot_1_anim_2' />
              </circle>
            </g>

            <g>
              <circle id="computer_graphic_loading_dot_2" cx="249" cy="150" r="5" fill="#2848e6" stroke="none" strokeMiterlimit="10" strokeWidth="1">
                <animate attributeName='r' from='5' to='10' dur='0.25s' begin='computer_graphic_loading_dot_1_anim_1.end' fill='freeze' id='computer_graphic_loading_dot_2_anim_1' />
                <animate attributeName='r' from='10' to='5' dur='0.25s' begin='computer_graphic_loading_dot_2_anim_1.end' fill='freeze' id='computer_graphic_loading_dot_2_anim_2' />
              </circle>
            </g>

            <g>
              <circle id="computer_graphic_loading_dot_3" cx="283" cy="150" r="5" fill="#2848e6" stroke="none" strokeMiterlimit="10" strokeWidth="1">
                <animate attributeName='r' from='5' to='10' dur='0.25s' begin='computer_graphic_loading_dot_2_anim_1.end' fill='freeze' id='computer_graphic_loading_dot_3_anim_1' />
                <animate attributeName='r' from='10' to='5' dur='0.25s' begin='computer_graphic_loading_dot_3_anim_1.end' fill='freeze' id='computer_graphic_loading_dot_3_anim_2' />
              </circle>
            </g>

            <animate attributeName='opacity' from='0' to='1' dur='0.5s' begin='0.25s' fill='freeze' id='computer_graphic_loading_dots_anim_1' />
            <animate attributeName='opacity' from='1' to='0' dur='0.5s' begin='3s' fill='freeze' id='computer_graphic_loading_dots_anim_2' />
          </g>
        </svg>
      </div>
    );
  }
}
