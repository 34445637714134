import React            from 'react';
import { Image }  from '../Misc';
import { connect }      from 'react-redux';
import { createMarkup, toggleNoScroll } from '../../services/misc';
import { setCurrentProject, setProjectModalStatus } from '../../services/redux/actions';


class ProjectPreview extends React.Component {

  constructor(props) {
    super(props);

    this.scrollModalToTop = this.scrollModalToTop.bind(this);
    this.setCurrentProject = this.setCurrentProject.bind(this);
  }

  // ==========================================================================

  scrollModalToTop() {
    const cols = document.querySelectorAll('.js-project-modal-scroll');

    cols.forEach((col) => {
      col.scrollTo(0, 0);
    });
  }

  // ==========================================================================

  setCurrentProject() {
    const main = document.querySelector('.js-main');

    if (this.props.project) {
      this.props.setCurrentProject(this.props.project);
      this.scrollModalToTop();
      toggleNoScroll(true);
      this.props.setProjectModalStatus(true);

      setTimeout(() => {
        main.classList.add('b-visually-hidden');
      }, 200);
    }
  }

  // ==========================================================================

  render() {
    return (
      <div className='pg-portfolio--preview'>
        <div className='pg-portfolio--preview--inner'>
          <div className='pg-portfolio--preview--image-wrapper' onClick={ this.setCurrentProject }>
            <Image
              src={ `/images/${ this.props.project.image }` }
              alt={ this.props.project.title }
              id={ `${ this.props.project.handle }-preview` }
              classes='outline hover-glow'
            />
          </div>

          <div className='pg-portfolio--preview--text-wrapper'>
            <div className='pg-portfolio--preview--header'>
              <h3 className='b-h3'>
                <span className='js-project-preview b-glow-blue-hover' onClick={ this.setCurrentProject } data-handle={ this.props.project.handle }>{ this.props.project.title }</span>
              </h3>
            </div>

            <div className='pg-portfolio--preview--content' dangerouslySetInnerHTML={ createMarkup(this.props.project.preview) } />
          </div>
        </div>
      </div>
    );
  }
}


// REDUX ======================================================================


const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentProject: (payload) => dispatch(setCurrentProject(payload)),
    setProjectModalStatus: (payload) => dispatch(setProjectModalStatus(payload))
  }
}

const component = connect(null, mapDispatchToProps)(ProjectPreview);

export default component;
