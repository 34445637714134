import React from 'react';


export default class Image extends React.Component {

  constructor(props) {
    super(props);

    this.setImageLoadedState = this.setImageLoadedState.bind(this);
  }

  // ==========================================================================

  componentDidMount() {
    this._isMounted = true;
  }

  // ==========================================================================

  componentWillUnmount() {
    this._isMounted = false;
  }

  // ==========================================================================

  setImageLoadedState() {
    const img = document.querySelector(`#${ this.props.id }`);

    if (this._isMounted && img) {
      img.classList.add('show');
    }
  }

  // ==========================================================================

  render() {
    return (
      <div id={ this.props.id } className={ `b-img-fade ${ this.props.classes ? this.props.classes : '' }` }>
        <img src={ this.props.src } alt={ this.props.alt } onLoad={ this.setImageLoadedState } />
      </div>
    );
  }
}
