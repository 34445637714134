import React from 'react';
import { PageFadeTransition } from '../Misc';
import { ProfessionalHero,
         ProfessionalPlatforms,
         ProfessionalTracking } from '.';


export default class Professional extends React.Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <PageFadeTransition sectionName='Professional'>
        <section className='pg-professional b-section--padding'>
          <div className='pg-professional--inner b-page-width'>
            <ProfessionalHero />
            <ProfessionalPlatforms />
            <ProfessionalTracking />
          </div>
        </section>
      </PageFadeTransition>
    );
  }
}
