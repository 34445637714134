import React from 'react';


export default class ResumeSection extends React.Component {
  render() {
    let header;
    let sectionClass;

    if (this.props.topSection) {
      header = (
        <h1 className='b-h1 b-h1-glow-blue'>
          <span>{ this.props.sectionName }</span>
        </h1>
      );
      sectionClass = 'pg-resume--section top';
    } else {
      header = <h2 className='b-h2 b-h2-glow-blue'>{ this.props.sectionName }</h2>
      sectionClass = 'pg-resume--section';
    }

    return (
      <div className={ sectionClass }>
        <div className='pg-resume--section--inner'>
          <div className='pg-resume--section--header pg-resume--section--col'>
            { header }
          </div>

          <div className='pg-resume--section--content pg-resume--section--col'>
            { this.props.children }
          </div>
        </div>
      </div>
    );
  }
}
