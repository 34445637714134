import React from 'react';
import { ProjectsFilter, ProjectsList } from '.';
import { NothingHere, PageFadeTransition } from '../Misc';
import { connect }       from 'react-redux';
import { getJsonData }   from '../../services/data';
import { setProjects }   from '../../services/redux/actions';


class Portfolio extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filterTags: [],
      hideProjects: false,
      dataNotFound: false
    };

    this.setTags   = this.setTags.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  // ==========================================================================

  componentWillMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);

    if (!this.props.projects.length) {
      this.fetchData();
    }
  }

  // ==========================================================================

  componentWillUnmount() {
    this._isMounted = false;
  }

  // ==========================================================================

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchData();
    }
  }

  // ==========================================================================

  fetchData() {
    const url = `/data/projects.json`;

    return getJsonData(url)
      .then((data) => {
        if (this._isMounted) {
          this.props.setProjects(data);
        }
      }).catch(() => {
        if (this._isMounted) {
          this.setState({ dataNotFound: true });
        }
      });
  }

  // ==========================================================================

  setTags(tags) {
    this.setState({ hideProjects: true });

    setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({ filterTags: tags, hideProjects: false });
    }, 500);
  }

  // ==========================================================================

  render() {
    const hideProjectsClass = this.state.hideProjects ? 'b-visually-hidden' : '';
    let content;

    if (this.props.projects.length) {
      content = (
        <div className='pg-portfolio--content'>
          <div className='pg-portfolio--filter-col'>
            <div className='pg-portfolio--filter-col-inner b-hidden-scrollbar js-fixed-sidebar'>
              <div className='pg-portfolio--header b-page-width--mobile-small'>
                <h1 className='b-h1 b-h1-glow-blue'>
                  <span>Portfolio</span>
                </h1>
              </div>

              <ProjectsFilter setTags={ this.setTags }/>
            </div>
          </div>

          <div className='pg-portfolio--list-col'>
            <div className={ `pg-portfolio--list-wrapper b-page-width--mobile-small ${ hideProjectsClass }` }>
              <ProjectsList filterTags={ this.state.filterTags } />
            </div>
          </div>
        </div>
      );
    } else if (this.state.dataNotFound) {
      content = <NothingHere />;
    }

    return (
      <PageFadeTransition sectionName='Portfolio'>
        <section className='pg-portfolio b-section--padding'>
          <div className='pg-portfolio--inner b-page-width--desktop-small'>
            { content }
          </div>
        </section>
      </PageFadeTransition>
    );
  }
}


// REDUX ======================================================================

const mapStateToProps = (state) => {
  return {
    projects: state.projects
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjects: (payload) => dispatch(setProjects(payload))
  }
}

const component = connect(mapStateToProps, mapDispatchToProps)(Portfolio);

export default component;
