import React from 'react';
import { HeaderModal } from '.';
import { ProjectModal } from '../Portfolio';
import { Link } from 'react-router-dom';
import { connect }      from 'react-redux';
import { toggleNoScroll } from '../../services/misc';
import { setProjectModalStatus } from '../../services/redux/actions';


class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
      isActive: false
    };

    this.openDropdown   = this.openDropdown.bind(this);
    this.closeDropdown  = this.closeDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  // ==========================================================================

  openDropdown() {
    const hamburger = document.querySelector('.js-hamburger');
    const main = document.querySelector('.js-main');

    toggleNoScroll(true);
    hamburger.classList.add('is-active');
    this.setState({ isActive: true });
    this.props.setProjectModalStatus(false);

    setTimeout(() => {
      main.classList.add('b-visually-hidden');
    }, 100);
  }

  // ==========================================================================

  closeDropdown() {
    const hamburger = document.querySelector('.js-hamburger');
    const main = document.querySelector('.js-main');

    toggleNoScroll();
    hamburger.classList.remove('is-active');
    this.setState({ isActive: false });
    this.props.setProjectModalStatus(false);

    setTimeout(() => {
      main.classList.remove('b-visually-hidden');
    }, 250);
  }

  // ==========================================================================

  toggleDropdown(e) {
    const hamburger = document.querySelector('.js-hamburger');

    if (hamburger.classList.contains('is-active')) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  // ==========================================================================

  render() {
    const hoverClass   = this.state.isHovering ? 'hover' : '';
    const activeClass  = this.state.isActive ? 'active' : '';
    const projectClass = this.props.projectModalOpen ? 'project-active' : '';

    return (
      <div className={ `s-header--wrapper js-header ${ activeClass } ${ projectClass }` }>

        <ProjectModal />
        <HeaderModal toggleDropdown={ this.toggleDropdown } />

        <div className={ `s-header ${ hoverClass } ${ activeClass }` }>
          <div className='s-header--inner b-page-width'>
            <div className='s-header--title'>
              <Link to='/resume'
                 onClick={ this.closeDropdown }
                 onMouseEnter={ () => this.setState({ isHovering: true }) }
                 onMouseLeave={ () => this.setState({ isHovering: false }) }>
                <p className='s-header--name b-h2 lite'>Ryan Gilbert</p>
              </Link>
              <p>Web Developer | Los Angeles</p>
            </div>

            <div className='s-header--hamburger'>
              <button className="hamburger hamburger--squeeze js-hamburger"
                      type="button"
                      onClick={ this.toggleDropdown }>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}



// REDUX ======================================================================


const mapStateToProps = (state) => {
  return {
    projectModalOpen: state.projectModalOpen
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjectModalStatus: (payload) => dispatch(setProjectModalStatus(payload))
  }
}

const component = connect(mapStateToProps, mapDispatchToProps)(Header);

export default component;
