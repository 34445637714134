import React from 'react';
import { ResumeSection } from '.';


export default class ResumeSummary extends React.Component {
  render() {
    return (
      <ResumeSection sectionName='Resume' topSection='true'>
        <p><span className='pg-resume--job-title'>Web Developer</span> specializing in <span className='b-font-medium'>JavaScript</span>, <span className='b-font-medium'>Ruby</span>, <span className='b-font-medium'>Shopify</span>, & <span className='b-font-medium'>Craft CMS</span>.</p>
        <p>Former Commercial Music Composer, New Havenite, metalhead, green tea connoisseur, 8-bit Nintendo enthusiast, and consistently broken-hearted NY Jets fan.</p>
        <p>Currently residing in sunny Los Angeles.</p>
        <p>
          <br/><br/>
          <b><a className='b-link-underline' href='mailto:ry.gil.online@gmail.com'><span>Contact</span></a></b>
        </p>
      </ResumeSection>
    );
  }
}
