import React from 'react';
import { toggleDropdown } from '../../services/misc';


export default class ProjectsFilter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filter: [
        {
          title: 'Scope',
          categories: [
            { 'title': 'Front End', 'handle': 'front-end' },
            { 'title': 'Full Stack', 'handle': 'full-stack' }
          ]
        },
        {
          title: 'Back End',
          categories: [
            { 'title': 'Ruby on Rails', 'handle': 'rails' },
            { 'title': 'Express', 'handle': 'express' }
          ]
        },
        {
          title: 'Front End',
          categories: [
            { 'title': 'Backbone', 'handle': 'backbone' },
            { 'title': 'Ember', 'handle': 'ember' },
            { 'title': 'jQuery', 'handle': 'jquery' },
            { 'title': 'React', 'handle': 'react' },
            { 'title': 'Vue', 'handle': 'vue' },
            { 'title': 'Vanilla JS', 'handle': 'vanilla' }
          ]
        },
        {
          title: 'API',
          categories: [
            { 'title': 'AWS', 'handle': 'aws' },
            { 'title': 'Coinbase', 'handle': 'crypto' },
            { 'title': 'Google Maps', 'handle': 'maps' },
            { 'title': 'Google OAuth', 'handle': 'oauth' },
            { 'title': 'Stripe', 'handle': 'stripe' },
            { 'title': 'YouTube', 'handle': 'youtube' }
          ]
        }
      ]
    }

    this.clearFilter = this.clearFilter.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.updateFilterTags = this.updateFilterTags.bind(this);
    this.updateSelectedCategories = this.updateSelectedCategories.bind(this);
  }

  // ==========================================================================

  updateSelectedCategories(e) {
    const target = e.currentTarget;
    const parent = target.parentElement;
    const siblings = parent.querySelectorAll('.js-filter-category');

    siblings.forEach((el) => {
      if (el === target && !target.classList.contains('active')) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    this.updateFilterTags();
  }

  // ==========================================================================

  updateFilterTags() {
    const active = document.querySelectorAll('.js-filter-category.active');
    const tags   = [];

    active.forEach((el) => {
      let tag = el.getAttribute('data-tag');

      if (tag) {
        tags.push(tag);
      }
    });

    this.props.setTags(tags);
  }

  // ==========================================================================

  toggleDropdown(e) {
    const target = e.currentTarget;
    toggleDropdown(target, 400);
  }

  // ==========================================================================

  clearFilter() {
    const selected = document.querySelectorAll('.js-filter-category.active');
    const open = document.querySelectorAll('.js-dropdown-wrapper.open > .js-dropdown-header');

    selected.forEach((el) => {
      el.classList.remove('active');
    });

    this.props.setTags([]);

    open.forEach((el) => {
      toggleDropdown(el, 400);
    });
  }

  // ==========================================================================

  render() {
    const content = this.state.filter.map((dropdown, i) => {
      let categories = dropdown.categories.map((category, j) => {
        return (
          <li className='pg-portfolio--filter--dropdown-category js-filter-category'
              onClick={ this.updateSelectedCategories }
              data-tag={ category.handle }
              data-category={ dropdown.title }
              key={ `Category - ${ i } - ${ j }` }>
            <span className='pg-portfolio--filter--dropdown-category-dash'></span>
            <span>{ category.title }</span>
          </li>
        );
      });

      return (
        <div className='pg-portfolio--filter--dropdown sub-dropdown js-dropdown-wrapper' key={ `Category - ${ i }` }>
          <div className='pg-portfolio--filter--dropdown-header b-page-width--mobile-small js-dropdown-header' onClick={ this.toggleDropdown }>
            <span>{ dropdown.title }</span>
            <span>
              <span className='plus'>+</span>
              <span className='minus'>–</span>
            </span>
          </div>

          <div className='pg-portfolio--filter--dropdown-list-wrapper js-dropdown'>
            <ul className='pg-portfolio--filter--dropdown-list b-page-width--mobile-small js-dropdown-content'>
              { categories }
            </ul>
          </div>
        </div>
      );
    });

    return (
      <div className='pg-portfolio--filter--wrapper'>
        <div className='pg-portfolio--filter pg-portfolio--filter--dropdown parent-dropdown js-dropdown-wrapper'>
          <div className='pg-portfolio--filter--dropdown-header b-page-width--mobile-small mobile-dropdown js-dropdown-header' onClick={ this.toggleDropdown }>
            <span>Filter</span>
            <span>
              <span className='plus'>+</span>
              <span className='minus'>–</span>
            </span>
          </div>

          <div className='pg-portfolio--filter--mobile-dropdown js-dropdown'>
            <div className='pg-portfolio--filter--inner js-dropdown-content'>
              { content }
            </div>
          </div>
        </div>

        <div className='pg-portfolio--filter--clear b-page-width--mobile-small' onClick={ this.clearFilter }>
          <span>Clear</span>
        </div>
      </div>
    );
  }
}
