import React            from 'react';
import { Image, NothingHere }  from '../Misc';
import { connect }      from 'react-redux';
import { setProjectModalStatus } from '../../services/redux/actions';
import { createMarkup, toggleNoScroll } from '../../services/misc';


class ProjectModal extends React.Component {

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  // ==========================================================================

  closeModal() {
    const main = document.querySelector('.js-main');

    toggleNoScroll();
    this.props.setProjectModalStatus(false);

    setTimeout(() => {
      main.classList.remove('b-visually-hidden');
    }, 250);
  }

  // ==========================================================================

  render() {
    let content;
    let images;
    let erdDesktop;
    let erdMobile;

    if (this.props.currentProject && this.props.currentProject.title) {
      if (this.props.currentProject.erd) {
        erdDesktop = (
          <li className='s-project-modal--image-wrapper mobile-hidden' key={ `${ this.props.currentProject.handle }-erd` }>
            <Image
              src={ `/images/${ this.props.currentProject.erd }` }
              alt='ERD'
              id={ `${ this.props.currentProject.handle }-erd` }
              classes='outline'
            />
          </li>
        );

        erdMobile = (
          <div className='s-project-modal--mobile-erd'>
            <Image
              src={ `/images/${ this.props.currentProject.erd }` }
              alt='ERD'
              id={ `${ this.props.currentProject.handle }-erd-mobile` }
              classes='outline'
            />
          </div>
        );
      }

      images = this.props.currentProject.images.map((imageSrc, i) => {
        return (
          <li className='s-project-modal--image-wrapper' key={ `${ this.props.currentProject.handle }-${ i }` }>
            <Image
              src={ `/images/${ imageSrc }` }
              alt={ `${ this.props.currentProject.title } - ${ i }` }
              id={ `${ this.props.currentProject.handle }-${ i }` }
              classes='outline'
            />
          </li>
        );
      });

      content = (
        <div className='s-project-modal--grid b-hidden-scrollbar js-project-modal-scroll'>
          <div className='s-project-modal--images s-project-modal--col b-hidden-scrollbar js-project-modal-scroll'>

            <div className='b-h4 b-link-arrow s-project-modal--close' onClick={ this.closeModal }>
              <span className='arrow-left'>←</span>
              <span>Back</span>
            </div>

            <div className='s-project-modal--images-wrapper s-project-modal--col-inner'>
              <div className='s-project-modal--images-list-wrapper b-hidden-scrollbar'>
                <ul className='s-project-modal--images-list'>
                  { erdDesktop }
                  { images }
                </ul>
              </div>
            </div>
          </div>

          <div className='s-project-modal--text s-project-modal--col b-hidden-scrollbar js-project-modal-scroll'>
            <div className='s-project-modal--col-inner b-page-width--mobile'>
              <div className='s-project-modal--header'>
                <h2 className='b-h1 b-font-blue-light'>{ this.props.currentProject.title }</h2>
              </div>

              <div className='s-project-modal--links'>
                <a href={ this.props.currentProject.website } target='_blank' rel="noopener noreferrer"><span>Live Website</span></a>
                <span>|</span>
                <a href={ this.props.currentProject.code } target='_blank' rel="noopener noreferrer"><span>Github</span></a>
              </div>

              <div className='s-project-modal--main-text' dangerouslySetInnerHTML={ createMarkup(this.props.currentProject.description) } />

              { erdMobile }
            </div>
          </div>
        </div>
      );
    } else {
      content = <NothingHere />;
    }


    return (
      <div className='s-project-modal'>
        <div className='s-project-modal--inner b-page-width--desktop'>
          { content }
        </div>
      </div>
    );
  }
}


// REDUX ======================================================================


const mapStateToProps = (state) => {
  return {
    currentProject: state.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjectModalStatus: (payload) => dispatch(setProjectModalStatus(payload))
  }
}

const component = connect(mapStateToProps, mapDispatchToProps)(ProjectModal);

export default component;
