import React from 'react';


export default class CraftIcon extends React.Component {
  render() {
    return (
      <a className='s-craft-icon s-graphic-icon-link' href='https://craftcms.com/' target='_blank' rel="noopener noreferrer">
        <svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px"
        	 viewBox="0 0 652 205" xmlSpace="preserve">
          <g id="Symbols">
          	<g id="Header-Signed-In" transform="translate(-114.000000, -320.000000)">
          		<path id="Shape" className="graphic-fill" d="M436.6,409.5H462l3-16.2h-25.4l4.3-23.7h-0.1l-19.3,15.7l-1.4,7.9h-20l5.8-31.9
          			c1.4-12,19-8.4,21.6-7.7l2.8-15.4c-31.7-12.7-40.9,23.1-40.9,23.1l-5.8,31.9h-0.1l-19.8,16.2h17L365,511.4h16.5l18.6-101.9h20
          			l-7.3,39.9c-4.6,29,23.3,25.8,32.2,24l2.8-15.2c-21.3,5-18.5-8.8-18.5-8.8L436.6,409.5L436.6,409.5z M338.5,450.4
          			c-18.4,19.4-30.2,6.7-30.2,6.7c-8-8,0.8-16.2,0.8-16.2c12.2-11.4,30.8,1.1,30.8,1.1L338.5,450.4z M341.2,430
          			c0,0-24.3-12.9-41.3,1.5c0,0-10,6.9-10,20.6c0,13.7,12.2,21.1,12.2,21.1s18.3,10,32.6-6l-1.6,7.4h16l10.1-49.3
          			c7.6-48.7-48.6-31.4-48.6-31.4l-3.1,17.5c41.4-18.6,34.4,13.8,34.4,13.8L341.2,430L341.2,430z M300,395.9
          			c-13.5,0.1-25.7,7.6-25.7,7.6l1.6-7.3h-16.1l-14.7,80h16.5l10-52.7c7.4-14.9,25.6-10.2,25.6-10.2L300,395.9L300,395.9z
          			 M235.3,360.9c0.8,0.6,1.6,1.3,2.3,2l12-9.7l0.4-0.5c-1.9-1.9-3.8-3.7-6-5.4c-27.6-21.4-69.2-12.4-93,20
          			c-23.8,32.4-20.8,76,6.8,97.4c22.5,17.4,54.4,14.7,78.3-4.6l0,0l-11.4-9.2c-17.7,11.7-39.7,12.5-55.6,0.2
          			c-21.2-16.4-23.5-49.9-5.2-74.8C182.1,351.3,214.1,344.4,235.3,360.9L235.3,360.9z M545,468.6c-3.6,2.3-7.5,4.1-11.5,5.5
          			c-4.2,1.4-8.7,2.1-13.6,2.1c-5.8,0-11.1-1-16-3.1c-4.7-1.9-9-4.8-12.6-8.5c-3.5-3.7-6.3-8-8.3-12.7c-2-4.9-3-10.1-3-15.7
          			c0-5.6,1-10.8,2.9-15.7c1.9-4.8,4.7-9.2,8.3-12.9c3.6-3.7,7.9-6.6,12.7-8.7c4.9-2.1,10.2-3.2,16-3.2c4.9,0,9.4,0.7,13.6,2.1
          			c4.2,1.4,8,3.3,11.6,5.5l-3.2,4.7c-3.1-2-6.5-3.6-10.1-4.9c-3.6-1.3-7.5-1.9-11.9-1.9c-5,0-9.6,0.9-13.7,2.7
          			c-4,1.7-7.6,4.2-10.6,7.3c-3,3.1-5.3,6.8-6.9,10.9c-1.6,4.3-2.5,9-2.4,13.6c0,4.8,0.8,9.4,2.4,13.7c1.5,4.1,3.9,7.9,6.9,11.2
          			c3,3.2,6.6,5.7,10.6,7.5c4.1,1.8,8.7,2.7,13.7,2.7c4.4,0,8.4-0.7,11.9-1.9c3.6-1.3,6.9-3,10.1-4.9L545,468.6L545,468.6z
          			 M664.2,476.2v-53c0-14.4-5.9-21.6-17.7-21.6c-5.4,0-10.6,1.7-15.5,4.9c-4.9,3.3-9.3,7.7-13.2,13.1v56.6h-5.8v-53
          			c0-14.4-5.9-21.6-17.7-21.6c-5.5,0-10.7,1.7-15.4,4.9c-4.9,3.4-9.4,7.5-13.3,12.2v57.5H560v-78.8h5.6v14.8c1.7-2,3.6-4,5.8-6
          			c2.2-2,4.5-3.7,6.9-5.2c2.5-1.5,5.1-2.8,8-3.7c2.9-1,6-1.4,9-1.4c5,0,9.5,1.5,13.3,4.4c3.8,2.9,6.4,7.4,7.8,13.5
          			c3.6-4.8,8.1-9,13.5-12.5c5.4-3.5,11.2-5.3,17.5-5.3c2.9,0,5.8,0.5,8.6,1.5c2.7,1,5.1,2.6,7.2,4.8c2.1,2.2,3.8,5,5,8.4
          			c1.2,3.4,1.8,7.4,1.8,12.1v53.5H664.2L664.2,476.2z M735,454.8c0,2.9-0.5,5.6-1.7,8.2c-1.1,2.6-2.7,4.9-4.8,6.8
          			c-2.2,2-4.7,3.6-7.5,4.7c-2.9,1.2-6.2,1.8-9.9,1.8c-4.9,0-9.5-0.8-13.8-2.5c-4.4-1.7-8.5-3.9-12.4-6.6l2.8-4.5
          			c3.5,2.4,7.2,4.5,11.1,6.1c3.8,1.6,7.9,2.4,12.4,2.4c5.5,0,10-1.5,13.3-4.5c3.3-3,5-6.6,5-11c0-3.7-1-6.7-3-8.9
          			c-2-2.2-4.5-4-7.5-5.6c-3-1.6-6.2-3-9.7-4.2c-3.4-1.2-6.6-2.7-9.7-4.5c-2.9-1.6-5.4-3.9-7.5-6.5c-2-2.6-3-6.1-3-10.4
          			c0-2.5,0.5-4.9,1.6-7.3c1.1-2.4,2.6-4.6,4.5-6.3c2.1-1.9,4.5-3.5,7.2-4.5c2.8-1.1,6.1-1.7,9.8-1.7c4.1,0,7.9,0.6,11.5,1.9
          			c3.6,1.2,7.2,3.1,10.6,5.6l-2.8,4.7c-3.3-2.5-6.5-4.3-9.5-5.4c-3.2-1.1-6.5-1.7-9.9-1.7c-5.9,0-10.3,1.5-13.2,4.4
          			c-2.9,2.9-4.3,6.2-4.3,9.8c0,3.7,1,6.7,3,8.9c2,2.2,4.5,4,7.5,5.5c3,1.5,6.2,2.9,9.7,4.1c3.4,1.2,6.7,2.7,9.7,4.4
          			c3,1.7,5.5,3.8,7.5,6.4C734,446.9,735,450.4,735,454.8L735,454.8z"/>
          	</g>
          </g>
        </svg>
      </a>
    );
  }
}
