export const setMessage = (message) => {
  return {
    type: 'SET_MESSAGE',
    payload: message
  }
};

export const setProjects = (projects) => {
  return {
    type: 'SET_PROJECTS',
    payload: projects
  }
};

export const setCurrentProject = (project) => {
  return {
    type: 'SET_CURRENT_PROJECT',
    payload: project
  }
};

export const setProjectModalStatus = (status) => {
  return {
    type: 'SET_PROJECT_MODAL_STATUS',
    payload: status
  }
};
