import React from 'react';
import { ResumeSection } from '.';


export default class ResumeExperience extends React.Component {
  render() {
    return (
      <ResumeSection sectionName='Experience'>
        <div className='pg-resume--experience'>
          <ul className='pg-resume--experience-list'>
            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--experience-content'>
                <h3 className='b-h4'>Web Developer</h3>

                <div className='pg-resume--content-details'>
                  <div className='pg-resume--experience-subtitle'>
                    <div className='title'>Superbolt</div>
                    <div>Santa Monica, CA</div>
                    <div>2018 - Present</div>
                  </div>

                  <ul>
                    <li>Built <a href='https://www.superbolt.agency' target='_blank' rel='noopener noreferrer'>Superbolt</a>'s company website using Craft CMS.</li>
                    <li>Built eCommerce Shopify stores for the company's clients.</li>
                    <li>Inherited and maintained existing Shopify stores.</li>
                    <li>Built back-end APIs with Ruby on Rails.</li>
                    <li>Created various landing pages and website widgets.</li>
                    <li>Set up purchase tracking networks for eCommerce stores using Facebook Pixels, Google Analytics, Google Tag Manager, and other tools.</li>
                    <li>Set up automated email flows for eCommerce stores using Klaviyo, Mailchimp, Listrak, and other tools.</li>
                  </ul>
                </div>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--experience-content'>
                <h3 className='b-h4'>Music Composer / Audio Engineer</h3>

                <div className='pg-resume--content-details'>
                  <div className='pg-resume--experience-subtitle'>
                    <div className='title'>Freelance</div>
                    <div>Los Angeles, CA</div>
                    <div>2015 - 2018</div>
                  </div>

                  <ul>
                    <li>Edited and mixed audio for various projects.</li>
                    <li>Composed, recorded, and mixed music tracks for music libraries and ad companies.</li>
                  </ul>
                </div>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--experience-content'>
                <h3 className='b-h4'>Music Composer / Audio Engineer</h3>

                <div className='pg-resume--content-details'>
                  <div className='pg-resume--experience-subtitle'>
                    <div className='title'>Elias Arts</div>
                    <div>Santa Monica, CA</div>
                    <div>2010 - 2014</div>
                  </div>

                  <ul>
                    <li>Composed, recorded, and mixed music tracks for the <a href='http://www.eliasmusiclibrary.com' target='_blank' rel='noopener noreferrer'>Elias Music Library</a>.</li>
                    <li>Arranged, extended, and re-mixed other composers' music tracks.</li>
                    <li>Offered studio musician services to other composers.</li>
                  </ul>
                </div>
              </div>
            </li>

            <li className='pg-resume--content-list-item'>
              <div className='pg-resume--experience-content'>
                <h3 className='b-h4'>Assistant Audio Engineer</h3>

                <div className='pg-resume--content-details'>
                  <div className='pg-resume--experience-subtitle'>
                    <div className='title'>Paramount Recording Studios</div>
                    <div>Los Angeles, CA</div>
                    <div>2009 - 2010</div>
                  </div>

                  <ul>
                    <li>Assisted Audio Engineers on music recording sessions.</li>
                    <li>Acted as Studio Manager during overnight recording sessions.</li>
                    <li>Managed microphone/gear inventory.</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ResumeSection>
    );
  }
}
