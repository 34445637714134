import React               from 'react';
import { Resume }          from '../Resume';
import { Homepage }        from '../Homepage';
import { Portfolio }       from '../Portfolio';
import { NothingHere }     from '../Misc';
import { Professional }    from '../Professional';
import { AnimatePresence } from 'framer-motion';
import { Route, Router, Switch }   from 'react-router-dom';


export default class Main extends React.Component {
  render() {
    return (
      <div className='s-main js-main'>
        <Router { ...this.props }>
          <AnimatePresence exitBeforeEnter initial={ false }>
            <Switch location={ this.props.location } key={ this.props.location.pathname }>
              <Route
                exact path='/'
                render={() => <Homepage /> }
              />

              <Route
                exact path='/resume'
                render={() => <Resume /> }
              />

              <Route
                exact path='/portfolio'
                render={() => <Portfolio { ...this.props } /> }
              />

              <Route
                exact path='/professional-work'
                render={() => <Professional { ...this.props } /> }
              />

              <Route
                path='/*'
                render={ () => <NothingHere /> }
              />
            </Switch>
          </AnimatePresence>
        </Router>
      </div>
    );
  }
}
