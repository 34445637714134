import React from 'react';
import { EmailIcon } from '../Graphics';


export default class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isHovering: false
    };
  }

  // ==========================================================================

  render() {
    const hoverClass = this.state.isHovering ? 'active' : '';

    return (
      <div className={ `s-footer js-footer ${ hoverClass }` }>
        <div className='s-footer--inner b-page-width'>
          <div className='s-footer--icon-wrapper'>
            <a href='mailto:ry.gil.online@gmail.com'
               className='s-footer--icon'
               onMouseEnter={ () => this.setState({ isHovering: true }) }
               onMouseLeave={ () => this.setState({ isHovering: false }) }
            >
              <EmailIcon />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
