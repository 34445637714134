import React from 'react';
import { NavLink } from 'react-router-dom';


export default class HeaderModal extends React.Component {
  render() {
    return (
      <div className='s-header--modal'>
        <div className='s-header--links b-section--padding b-page-width'>
          <ul className='s-header--links-list'>
            {/* <li className='s-header--links-list-item b-h1'>
              <NavLink to='/' exact className='s-header--links-link' activeClassName='active' onClick={ this.props.toggleDropdown }>
                <span>Home</span>
              </NavLink>
            </li> */}

            <li className='s-header--links-list-item b-h1'>
              <NavLink to='/resume' exact className='s-header--links-link' activeClassName='active' onClick={ this.props.toggleDropdown }>
                <span>Resume</span>
              </NavLink>
            </li>

            <li className='s-header--links-list-item b-h1'>
              <NavLink to='/portfolio' exact className='s-header--links-link' activeClassName='active' onClick={ this.props.toggleDropdown }>
                <span>Portfolio</span>
              </NavLink>
            </li>

            <li className='s-header--links-list-item b-h1'>
              <NavLink to='/professional-work' exact className='s-header--links-link' activeClassName='active' onClick={ this.props.toggleDropdown }>
                <span>Professional Work</span>
              </NavLink>
            </li>

            <li className='s-header--links-list-item b-h1'>
              <a href='mailto:ry.gil.online@gmail.com' className='s-header--links-link'>
                <span>Contact</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
