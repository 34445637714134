export default (e) => {
  const sidebar = document.querySelector('.js-fixed-sidebar');
  const footer  = document.querySelector('.js-footer');
  const main    = document.querySelector('.js-main');

  if (sidebar && footer && main) {
    let footerBounds = footer.getBoundingClientRect();
    let footerDiff   = footerBounds.top - window.innerHeight;
    let sidebarDiff  = sidebar.scrollHeight - window.innerHeight;

    if (footerDiff < 0) {
      sidebar.style.top = `${ footerDiff }px`;
    } else {
      sidebar.style.top = '0';
    }

    if (sidebarDiff && footerDiff < sidebarDiff && footerDiff > 0) {
      sidebar.scrollTop = sidebarDiff - footerDiff;
    }
  }
}
